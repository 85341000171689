




































































import { Company, User, CompanyAuth } from '@app/models';
import fb from 'firebase/app';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import { ISOdateToNormal, date } from '../../utils/simple-functions';

import DialogCompanyUser from '../../components/3-dialogs/dialog-company-user.vue';

@Component({
  components: { DialogCompanyUser },
})
export default class CompanyUsers extends Vue {
  @Prop({ type: Object })
  public company!: Company;

  public currentPage = 1;
  public dialog = false;
  public perPage = 10;
  public user: User | null = null;

  public get addSelf() {
    const isMember = this.users.some(user => user.uid === this.$user.id);
    return this.$user.admin && !isMember;
  }

  public get users() {
    const users = this.$user.list;

    return users
      .filter(user => this.company.users.indexOf(user.uid) >= 0)
      .map(user => {
        const auth = this.company.auth[user.uid];
        const roles = auth ? auth.roles : [];
        return { ...user, roles };
      });
  }

  public get fields() {
    return [
      {
        key: 'created',
        label: this.$t('creationDate'),
        sortable: true,
        formatter: (value: User['created']) => ISOdateToNormal(date(value)),
      },
      {
        key: 'status.complete',
        label: this.$t('complete'),
        sortable: true,
        formatter: (value: User['status']['complete']) => this.$t('' + value),
      },
      {
        key: 'name',
        label: this.$t('name'),
        sortable: true,
        formatter: (value: User['name']) => value.display,
      },
      {
        key: 'email',
        label: this.$t('email'),
        sortable: true,
      },
      {
        key: 'roles',
        label: this.$t('roles'),
        sortable: true,
        formatter: (value: Company['auth'][string]['roles']) => {
          return value ? value.join(', ') : '';
        },
      },
      this.$company.permission('company-users') && {
        key: 'actions',
        label: '',
        thClass: 'th-actions',
        tdClass: 'td-actions',
      },
    ];
  }

  public async dialogSave(data: Partial<CompanyAuth> & { email?: string }) {
    if (!this.user && data.email) {
      const { email, ...auth } = data;
      this.$app.loader(this.$t('loader-message-1'));
      await this.$firebase.company.invite(this.company.id, email, auth);
      this.$app.loader();
    }

    if (this.user) {
      const auth = this.company.auth[this.user.uid] || {};
      await this.$firebase
        .doc(`company/${this.company.id}`)
        .update({ [`auth.${this.user!.uid}`]: { ...auth, ...data } });
    }

    this.dialogDiscard();
  }

  public dialogDiscard() {
    this.dialog = false;
    this.user = null;
  }

  public roleEdit(user: User) {
    this.dialog = true;
    this.user = user;
  }

  public selfAdd() {
    const users = fb.firestore.FieldValue.arrayUnion(this.$user.id);
    this.$firebase.doc(`company/${this.company.id}`).update({ users });
  }

  public async userAdd() {
    this.dialog = true;
  }

  public userRemove(user: User) {
    const users = fb.firestore.FieldValue.arrayRemove(user.uid);
    this.$firebase.doc(`company/${this.company.id}`).update({ users });
  }
}
