




























































import { Company, CompanyAuth, User } from '@app/models';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class DialogCompanyUser extends Vue {
  @Prop({ type: Object })
  public company!: Company;

  @Prop({ type: Object })
  public user?: User;

  public access: CompanyAuth['access'] = {};
  public email: string = '';
  public roles: CompanyAuth['roles'] = [];

  created() {
    const auth = this.user && this.company.auth[this.user.uid];
    if (!this.user || !auth) return;

    this.access = auth.access;
    this.email = this.user.email;
    this.roles = auth.roles;

    for (const category of this.company.categories) {
      const { name } = category;
      if (!(name in this.access)) this.access[name] = [];
    }
  }

  public discard() {
    const dialog = this.$el as HTMLBceDialogElement;
    dialog.hide();
    this.$emit('discard');
  }

  public async submit() {
    const form = this.$el.querySelector('bce-dialog');
    const errors = form && (await (form as any).validate(true));
    if (errors && errors.length) return;

    const submit = {
      access: this.access,
      email: this.email,
      roles: this.roles,
    };

    this.$emit('submit', submit);
  }
}
